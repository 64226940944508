import React, { useState } from "react";
import Layout from "../components/Layout";
import { PositionTable } from "../components/PositionList";
import OrdersGrid from "../components/OrdersGrid";
import DealsGrid from "../components/DealsGrid";
import "bootstrap-icons/font/bootstrap-icons.css"; // Asegúrate de importar Bootstrap Icons
import "../styles/Reports.css";

const Reports = () => {
    const [activeTab, setActiveTab] = useState("openPositions");
    
    const renderContent = () => {
        switch (activeTab) {
            case "openPositions":
                return <PositionTable />;
            case "orders":
                return <OrdersGrid />;
            case "deals":
                return <DealsGrid />;
            default:
                return <PositionTable />;
        }
    };

    return (
        <Layout>
            <div className="reports-container">
                {/* Tabs */}
                <div className="tabs-container">
                    <div className={`tab-container ${activeTab === "openPositions" ? "active" : ""}`}
                        onClick={() => setActiveTab("openPositions")}
                    >
                        <span className={`tab-button ${activeTab === "openPositions" ? "active" : ""}`} >Open Positions</span>
                    </div>
                    <div className={`tab-container ${activeTab === "orders" ? "active" : ""}`}
                        onClick={() => setActiveTab("orders")}>
                        <span className={`tab-button ${activeTab === "orders" ? "active" : ""}`}>Orders</span>
                    </div>

                    <div className={`tab-container ${activeTab === "deals" ? "active" : ""}`}
                        onClick={() => setActiveTab("deals")}
                    >
                        <span className={`tab-button ${activeTab === "deals" ? "active" : ""}`}>Deals</span>
                    </div>
                </div>

                {/* Content */}
                <div className="content-container">{renderContent()}</div>
            </div>
        </Layout>
    );
};

export default Reports;
