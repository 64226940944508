import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = ({ setCaptchaValue }) => {
    const { t } = useTranslation(); // Hook de traducción
    const [captchaKey, setCaptchaKey] = useState('');

    useEffect(() => {
        const fetchCaptchaKey = async () => {
            const localKey = localStorage.getItem('recaptcha_site_key'); // Verifica si la clave está en localStorage
            const apiUrl = process.env.REACT_APP_API_URL;
            if (localKey) {
                setCaptchaKey(localKey); // Usa la clave de localStorage
            } else {
                try {
                    const response = await fetch(`${apiUrl}/recaptcha/recaptcha-key/`); // Ajusta la URL según tu configuración
                    const data = await response.json();
                    setCaptchaKey(data.site_key); // Configura la clave obtenida
                    localStorage.setItem('recaptcha_site_key', data.site_key); // Guarda la clave en localStorage
                } catch (error) {
                    console.error(t('Error fetching reCAPTCHA key'), error);
                }
            }
        };

        fetchCaptchaKey();
    }, [t]); // Incluye `t` como dependencia por si cambia el idioma

    return (
        <div>
            {captchaKey && ( // Renderiza reCAPTCHA solo si la clave está disponible
                <ReCAPTCHA
                    sitekey={captchaKey} // Usa la clave obtenida
                    onChange={(value) => setCaptchaValue(value)} // Maneja el valor del CAPTCHA
                />
            )}
        </div>
    );
};

export default Captcha;
