import React from 'react';
import PropTypes from 'prop-types';
import '../styles/Switch.css';

const Switch = ({ name, checked, onChange, values, labels }) => {
  const handleToggle = () => {
    const newValue = checked === values[0] ? values[1] : values[0];
    onChange({ target: { name, value: newValue } });
  };

  return (
    <div
      className={`switch ${checked === values[0] ? 'switch--on' : 'switch--off'}`}
      onClick={handleToggle}
      data-testid={`dt_switch_${name}`}
    >
      <div className="switch__circle"></div>
      <span className="switch__label">
        {labels ? (checked === values[0] ? labels[0] : labels[1]) : checked.toString()}
      </span>
    </div>
  );
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,                // Nombre del campo en el formulario
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired, 
  onChange: PropTypes.func.isRequired,              // Callback para manejar el cambio
  values: PropTypes.array.isRequired,               // Valores posibles del switch [on, off]
  labels: PropTypes.arrayOf(PropTypes.string),      // Etiquetas opcionales para el switch
};

export default Switch;
