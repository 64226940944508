import { atom } from 'recoil';

export const authTokenAtom = atom({
    key: 'authTokenAtom',
    default: localStorage.getItem('token') || null,
});

export const refreshTokenAtom = atom({
    key: 'refreshTokenAtom',
    default: localStorage.getItem('refreshToken') || null,
});

export const userAtom = atom({
    key: 'userAtom',
    default: null,
});

export const timeframesAtom = atom({
    key: 'timeframesAtom',
    default: JSON.parse(localStorage.getItem('timeframes')) || [],
});

export const symbolSectorsAtom = atom({
    key: 'symbolSectorsAtom',
    default: JSON.parse(localStorage.getItem('symbolSectors')) || [],
});

export const symbolIndustriesAtom = atom({
    key: 'symbolIndustriesAtom',
    default: JSON.parse(localStorage.getItem('symbolIndustries')) || [],
});

export const symbolsAtom = atom({
    key: 'symbolsAtom',
    default: JSON.parse(localStorage.getItem('symbols')) || [],
});

export const syntheticSymbolsAtom = atom({
    key: 'syntheticSymbolsAtom',
    default: JSON.parse(localStorage.getItem('syntheticSymbols')) || [],
});

export const ticksAtom = atom({
    key: 'ticksAtom',
    default: JSON.parse(localStorage.getItem('ticks')) || [],
});

export const accountsAtom = atom({
    key: 'accountsAtom',
    default: JSON.parse(localStorage.getItem('accounts')) || [],
});

export const ordersAtom = atom({
    key: 'ordersAtom',
    default: JSON.parse(localStorage.getItem('orders')) || [],
});

export const dealsAtom = atom({
    key: 'dealsAtom',
    default: JSON.parse(localStorage.getItem('deals')) || [],
});

export const positionsAtom = atom({
    key: 'positionsAtom',
    default: JSON.parse(localStorage.getItem('positions')) || [],
});

export const candlesAtom = atom({
    key: 'candlesAtom',
    default: JSON.parse(localStorage.getItem('candles')) || [],
});

export const selectedSymbolAtom = atom({
    key: 'selectedSymbolAtom',
    default: JSON.parse(localStorage.getItem('selectedSymbol')) || null,
});

export const selectedTimeframeAtom = atom({
    key: 'selectedTimeframeAtom',
    default: JSON.parse(localStorage.getItem('selectedTimeframe')) || null,
});

export const symbolInfoTickAtom = atom({
    key: 'symbolInfoTickAtom',
    default: JSON.parse(localStorage.getItem('symbolInfoTick')) || null,
});

export const selectedAccountAtom = atom({
    key: 'selectedAccountAtom',
    default: JSON.parse(localStorage.getItem('selectedAccount')) || null,
});

export const positionsEndpointAtom = atom({
    key: 'positionsEndpointAtom',
    default: localStorage.getItem('positionsEndpoint') || null,
});
export const ordersEndpointAtom = atom({
    key: 'ordersEndpointAtom',
    default: localStorage.getItem('ordersEndpoint') || null,
});
export const dealsEndpointAtom = atom({
    key: 'dealsEndpointAtom',
    default: localStorage.getItem('dealsEndpoint') || null,
});