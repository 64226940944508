// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthenticationProvider } from './contexts/AuthenticationContext';
import Login from './pages/Login';
import Register from './pages/Register';
import PasswordResetRequest from './pages/PasswordResetRequest';
import PasswordResetConfirm from './pages/PasswordResetConfirm';
import Dashboard from './pages/Dashboard'; // Ajusta las rutas según tu estructura
import Founds from './pages/Founds';
import Reports from './pages/Reports';
import ProtectedRoute from './components/ProtectedRoute';
import ThemeProvider from './components/ThemeProvider';
import BotTemplates from './pages/BotTemplates';

function App() {
    return (
        <AuthenticationProvider>
            <ThemeProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        } />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/password-reset" element={<PasswordResetRequest />} />
                        <Route path="/reset-password/:uidb64/:token" element={<PasswordResetConfirm />} />
                        <Route path="/reports" element={
                            <ProtectedRoute>
                                <Reports />
                            </ProtectedRoute>
                        } />
                        <Route path="/templates" element={
                            <ProtectedRoute>
                                <BotTemplates />
                            </ProtectedRoute>
                        } />
                    </Routes>
                </Router>
            </ThemeProvider>
        </AuthenticationProvider>
    );
}

export default App;
