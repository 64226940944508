import * as XLSX from "xlsx";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { authTokenAtom } from "../atoms";

/**
 * Obtiene todos los datos paginados del backend.
 * @param {string} apiUrl - La URL del endpoint.
 * @param {string} authToken - Token de autenticación.
 * @param {object} params - Parámetros adicionales para la solicitud.
 * @returns {Promise<Array>} - Retorna todos los datos combinados.
 */
const fetchAllData = async (apiUrl, authToken, params = {}) => {
    let allData = [];
    let nextPageUrl = apiUrl;
    params = { page_size: 1000, ...params };

    try {
        const firstResponse = await axios.get(nextPageUrl, {
            headers: { Authorization: `Bearer ${authToken}` },
            params: params, // Solicitar 100 registros por página
        });
        allData = [...firstResponse.data.results];
        nextPageUrl = firstResponse.data.next;
        while (nextPageUrl) {
            authToken = localStorage.getItem('token')
            const response = await axios.get(nextPageUrl, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            allData = [...allData, ...response.data.results];
            nextPageUrl = response.data.next; // URL para la siguiente página
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }

    return allData;
};

/**
 * Exporta datos a un archivo Excel.
 * @param {Array} data - Datos a exportar (array de objetos).
 * @param {string} fileName - Nombre del archivo Excel.
 */
const exportToExcel = (data, fileName) => {
    if (!data || data.length === 0) {
        alert("No data available to export.");
        return;
    }

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export { fetchAllData, exportToExcel };