import React, { useState } from "react";
import { exportToExcel, fetchAllData } from "../utils/ExportExcel";
import { useRecoilValue } from "recoil";
import { authTokenAtom } from "../atoms";
import { format } from "date-fns";
import '../styles/ExportButtonWithProgress.css';

const ExportButtonWithProgress = ({ className = "", fileName = "ExportedData", apiUrl = null, data = null, params = {} }) => {
    const authToken = useRecoilValue(authTokenAtom);
    // const apiUrl = useRecoilValue(positionsEndpointAtom);
    const [loading, setLoading] = useState(false); // Estado de carga
    const [status, setStatus] = useState(""); // Mensaje de progreso

    const handleExport = async () => {
        try {
            let allData = {};
            setLoading(true); // Activa el estado de carga
            setStatus("Fetching data...");
            // Recuperar todos los datos
            if (!data) {
                allData = await fetchAllData(apiUrl, authToken, params);
            }
            else {
                allData = data;
            }

            const date = format(new Date(Date.now()), "yyyyMMdd_HHmmss");
            setStatus("Generating Excel file...");
            exportToExcel(allData, `${fileName}_${date}`);

            setStatus("File generated successfully!");
        } catch (error) {
            console.error("Error exporting data:", error);
            setStatus("An error occurred while generating the file.");
        } finally {
            setLoading(false); // Desactiva el estado de carga
        }
    };

    return (
        <button
            onClick={handleExport}
            className="export-button"
            disabled={loading} // Desactiva el botón mientras está cargando
        >
            {loading ? (
                <>
                    <i className="bi bi-hourglass-split spin"></i> Processing...
                </>
            ) : (
                <>
                    <i className="bi bi-download"></i> Export to Excel
                </>
            )}
            {status && (
                <div className="export-status">
                    {loading ? (
                        <span>{status} <i className="bi bi-arrow-clockwise"></i></span>
                    ) : (
                        <span>{status}</span>
                    )}
                </div>
            )}
        </button>
    );
};

export default ExportButtonWithProgress;
