import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../styles/NetworkStatus.css';
import { setApiOnlineStatus } from '../utils/AxiosInterceptor';

const NetworkStatus = ({ interval = 5000 }) => {
  const [isOnline, setIsOnline] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkApiStatus = async () => {
      try {
        await axios.get(`${apiUrl}/market-data/health/`);
        if (!isOnline) {
          // Si el API se recupera, actualizar el estado
          setIsOnline(true);
          setShowModal(false);
          setApiOnlineStatus(true); // Habilitar solicitudes en el interceptor
        }
      } catch (error) {
        if (isOnline) {
          // Si se detecta una desconexión, actualizar el estado
          setIsOnline(false);
          setShowModal(true);
          setApiOnlineStatus(false); // Bloquear solicitudes en el interceptor
        }
      }
    };

    // Ejecutar el health check al cargar el componente
    checkApiStatus();

    // Intervalo para comprobar el estado del API periódicamente
    const intervalId = setInterval(checkApiStatus, interval);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [apiUrl, interval, isOnline]);

  return (
    <div data-testid="dt_network_status" className="network-status__wrapper">
      <div className="" data-testid="dt_popover_wrapper">
        <div className="dc-popover">
          <div className="dc-popover__target">
            <i className="dc-popover__target__icon"></i>
            <div
              data-testid="dt_network_status_element"
              className={`network-status__circle ${
                isOnline
                  ? 'network-status__circle--online'
                  : 'network-status__circle--offline'
              }`}
            ></div>
          </div>
        </div>
      </div>

      {/* Modal de desconexión */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>{t('Disconnected')}</h2>
            <p>{t('The backend is not available. Please try again later')}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default NetworkStatus;
