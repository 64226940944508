import React, { useState } from "react";
import Layout from "../components/Layout";
import "bootstrap-icons/font/bootstrap-icons.css"; // Asegúrate de importar Bootstrap Icons
import SymbolTemplateGrid from "../components/SymbolTemplateGrid";
import SymbolTemplateCards from "../components/SymbolTemplateCards";

const BotTemplates = () => {

    return (
        <Layout>
            <div className="templates-container">
                <div className="content-container">
                    <SymbolTemplateCards />
                </div>
            </div>
        </Layout>
    );
};

export default BotTemplates;
