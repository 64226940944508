import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción
import classNames from 'classnames';
import axios from 'axios';
import { positionsAtom, selectedSymbolAtom, authTokenAtom } from '../atoms';
import { FlexRow } from './FlexRow';
import '../styles/OpenPositionsGrid.css';
import { Tooltip } from "react-tooltip";
import ExportButtonWithProgress from './ExportButtonWithProgress';

const PositionDetails = ({ position, positionIndex }) => {
    const { t } = useTranslation(); // Hook de traducción
    const authToken = useRecoilValue(authTokenAtom);

    const handleClosePosition = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(
                `${apiUrl}/market-data/orders/close/${position.id}/`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                }
            );
            console.log(t('Position closed'), response.data);
        } catch (error) {
            if (error.response) {
                console.error(t('Error closing position'), error.response.data.error);
                alert(`${t('Error closing position')}: ${error.response.data.error}`);
            } else {
                console.error(t('Error closing position'), error);
                alert(`${t('Error closing position')}: ${error.message}`);
            }
        }
    };

    return (
        <div style={{
            height: position.state === 0 ? "384px" : "auto",
            left: "0px",
            position: "absolute",
            top: (positionIndex * 384) + "px",
            width: "100%"
        }}>
            <div className="data-list__row--wrapper" style={{ paddingBottom: "8px" }}>
                <div className="data-list__item--wrapper data-list__item--dynamic-height-wrapper">
                    <div className="data-list__item">
                        <div className="dc-contract-card__wrapper">
                            {position.state === 1 && (
                                <div className="dc-contract-card__result dc-result__positions-overlay dc-contract-card__result--lost dc-contract-card__result--enter-done">
                                    <span className="dc-result__close-btn"></span>
                                    <a className="dc-result__caption-wrapper" href={`/position/${position.id}`}></a>
                                    <div className="dc-result__content">
                                        <span className="dc-result__caption dc-result__caption--lost">{t('Closed')}</span>
                                        <span className="dc-text" style={{
                                            "--text-size": "var(--text-size-s)",
                                            "--text-color": "var(--text-loss-danger)",
                                            "--text-lh": "var(--text-lh-xxl)",
                                            "--text-weight": "var(--text-weight-bold)",
                                            "--text-align": "var(--text-align-left)"
                                        }}>-<span data-testid="dt_span">{position.profit}</span></span>
                                    </div>
                                </div>
                            )}
                            <div id={"dc_contract_card_" + position.id}>
                                <a className={classNames("dc-contract-card", { "dc-contract-card--green": position.profit > 0, "dc-contract-card--red": position.profit <= 0 })} href={`/position/${position.id}`}>
                                    <div className="dc-contract-card__grid dc-contract-card__grid-underlying-trade dc-contract-card__grid-underlying-trade--trader dc-contract-card__grid-underlying-trade--trader--sold">
                                        <div id="dc-contract_card_underlying_label" className="dc-contract-card__underlying-name">
                                            <span className="dc-text dc-contract-card__symbol" style={{
                                                "--text-size": "var(--text-size-xxs)",
                                                "--text-color": "var(--text-general)",
                                                "--text-lh": "var(--text-lh-m)",
                                                "--text-weight": "var(--text-weight-bold)",
                                                "--text-align": "var(--text-align-left)"
                                            }}>{position.symbol}</span>
                                        </div>
                                        <div id="dc-contract_card_type_label" className="dc-contract-card__type">
                                            <div className="dc-contract-type">
                                                <div className="dc-contract-type__type-wrapper">

                                                </div>
                                                <div className="dc-contract-type__type-label dc-contract-type__type-label--multipliers">
                                                    <div> {position.type === 1 ? t('Sell') : t('Buy')}</div>
                                                    <div className="dc-contract-type__type-label-trade-param">
                                                        {position.type === 1 ? (
                                                            <><i className="bi bi-arrow-down-right"></i>
                                                                <i className="bi bi-arrow-down-right"></i>
                                                                <i className="bi bi-arrow-down-right"></i>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className="bi bi-arrow-up-right"></i>
                                                                <i className="bi bi-arrow-up-right"></i>
                                                                <i className="bi bi-arrow-up-right"></i>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dc-progress-slider--completed">
                                    </div>
                                    <span className="dc-text dc-currency-badge" style={{
                                        "--text-size": "var(--text-size-xxxs)",
                                        "--text-color": "var(--text-colored-background)",
                                        "--text-lh": "var(--text-lh-unset)",
                                        "--text-weight": "var(--text-weight-bold)",
                                        "--text-align": "var(--text-align-left)"
                                    }}>{position.id}</span>
                                    <div className="dc-contract-card-items-wrapper" data-testid="dt_multiplier_card_body">
                                        <div className="dc-contract-card-item dc-contract-card__stake">
                                            <div className="dc-contract-card-item__header">{t('Open Price')}:</div>
                                            <div className="dc-contract-card-item__body">
                                                <span data-testid="dt_span">{position.open_price} </span>
                                            </div>
                                        </div>
                                        <div className="dc-contract-card-item dc-contract-card__current-stake">
                                            <div className="dc-contract-card-item__header">{t('Current Price')}:</div>
                                            <div className="dc-contract-card-item__body">
                                                <div className={classNames({ "dc-contract-card--loss": position.profit < 0, "dc-contract-card--profit": position.profit >= 0 })}>
                                                    <span data-testid="dt_span">{position.current_price} </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dc-contract-card-item dc-contract-card__deal-cancel-fee dc-contract-card__deal-cancel-fee__disabled">
                                            <div className="dc-contract-card-item__header">{t('Time')}:</div>
                                            <div className="dc-contract-card-item__body">{new Date(position.time).toLocaleString()}</div>
                                        </div>
                                        <div className="dc-contract-card-item dc-contract-card__buy-price">
                                            <div className="dc-contract-card-item__header">{t('Volume')}:</div>
                                            <div className="dc-contract-card-item__body">
                                                <span data-testid="dt_span">{position.volume} </span>
                                            </div>
                                        </div>
                                        <div className="dc-contract-card__limit-order-info">
                                            <div className="dc-contract-card-item dc-contract-card__take-profit">
                                                <div className="dc-contract-card-item__header">{t('Take profit')}:</div>
                                                <div className="dc-contract-card-item__body">
                                                    <span data-testid="dt_span">{position.tp} </span>
                                                </div>
                                            </div>
                                            <div className="dc-contract-card-item dc-contract-card__stop-loss">
                                                <div className="dc-contract-card-item__header">{t('Stop loss')}:</div>
                                                <div className="dc-contract-card-item__body">
                                                    <span data-testid="dt_span">{position.sl} </span>
                                                </div>
                                            </div>
                                            <div>
                                                <button className="dc-contract-card-dialog-toggle">
                                                    <i className="bi bi-pencil-fill dc-icon dc-contract-card-dialog-toggle__icon"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dc-contract-card-item dc-contract-card-item__total-profit-loss">
                                        <div className="dc-contract-card-item__header">{t('Profit')}:</div>
                                        <div className={classNames("dc-contract-card-item__body", { "dc-contract-card-item__body--loss": position.profit <= 0, "dc-contract-card-item__body--profit": position.profit > 0, })}>
                                            <span data-testid="dt_span">{Math.abs(position.profit)} </span>
                                        </div>
                                    </div>
                                </a>
                                <div className="dc-contract-card-item__footer">
                                    <div className="dc-contract-card__sell-button dc-contract-card__sell-button--positions">
                                        <button
                                            id={"dc_contract_card_" + position.id + "_button"}
                                            className="dc-btn dc-btn--secondary dc-btn--sell"
                                            tabIndex="0"
                                            type="button"
                                            onClick={handleClosePosition}
                                        >
                                            <span className="dc-text dc-btn__text" style={{
                                                "--text-size": "var(--text-size-xs)",
                                                "--text-color": "var(--text-general)",
                                                "--text-lh": "var(--text-lh-m)",
                                                "--text-weight": "var(--text-weight-bold)",
                                                "--text-align": "var(--text-align-center)"
                                            }}>{t('Close')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PositionListDrawer = () => {
    const positions = useRecoilValue(positionsAtom);
    const selectedSymbol = useRecoilValue(selectedSymbolAtom);
    const filteredPositions = positions.filter((position) => position.symbol === selectedSymbol?.name);

    return (
        <div className="positions-drawer__body">
            <div data-testid="dt_data_list" className="data-list">
                <div className="data-list__body-wrapper">
                    <div className="data-list__body" style={{ position: "relative" }}>
                        <div style={{
                            overflow: "visible",
                            height: "0px",
                            width: "0px"
                        }}>
                            <div style={{
                                height: "calc(-225px + 100vh)",
                                width: "238px"
                            }}>
                                <div data-testid="dt_themed_scrollbars" className="dc-themed-scrollbars dc-themed-scrollbars__autohide" style={{
                                    maxHeight: "100%",
                                    maxWidth: "none"
                                }}>
                                    <div aria-label="grid" aria-readonly="true" className="ReactVirtualized__Grid ReactVirtualized__List" role="grid" tabIndex="0" style={{
                                        boxSizing: "border-box",
                                        direction: "ltr",
                                        height: "auto",
                                        position: "relative",
                                        width: "238px",
                                        willChange: "transform",
                                        overflow: "auto"
                                    }}>
                                        <div className="ReactVirtualized__Grid__innerScrollContainer" role="rowgroup" style={{
                                            width: "auto",
                                            height: (filteredPositions.length * 384) + "px",
                                            maxWidth: "238px",
                                            maxHeight: (filteredPositions.length * 384) + "px",
                                            overflow: "hidden",
                                            position: "relative"
                                        }}>
                                            {filteredPositions
                                                .sort((a, b) => a.id - b.id)
                                                .map((position, index) => (
                                                    <FlexRow key={position.id}>
                                                        <PositionDetails position={position} positionIndex={index} />
                                                    </FlexRow>
                                                ))}
                                            {/* <div style={{
                                                    height: "384px",
                                                    left: "0px",
                                                    position: "absolute",
                                                    top: "315px",
                                                    width: "100%"
                                                }}>
                                                    <div className="data-list__row--wrapper" style={{ paddingBottom: "8px" }}>
                                                        <div className="data-list__item--wrapper data-list__item--dynamic-height-wrapper">
                                                            <div className="data-list__item">
                                                                <div className="dc-contract-card__wrapper">
                                                                    <div id="dc_contract_card_258294919888">
                                                                        <a className="dc-contract-card dc-contract-card--green" href="/contract/258294919888">
                                                                            <div className="dc-contract-card__grid dc-contract-card__grid-underlying-trade dc-contract-card__grid-underlying-trade--trader">
                                                                                <div id="dc-contract_card_underlying_label" className="dc-contract-card__underlying-name">

                                                                                    <span className="dc-text dc-contract-card__symbol" style={{
                                                                                        "--text-size": "var(--text-size-xxs)",
                                                                                        "--text-color": "var(--text-general)",
                                                                                        "--text-lh": "var(--text-lh-m)",
                                                                                        "--text-weight": "var(--text-weight-bold)",
                                                                                        "--text-align": "var(--text-align-left)"
                                                                                    }}>BTC/USD</span>
                                                                                </div>
                                                                                <div id="dc-contract_card_type_label" className="dc-contract-card__type">
                                                                                    <div className="dc-contract-type">
                                                                                        <div className="dc-contract-type__type-wrapper">

                                                                                        </div>
                                                                                        <div className="dc-contract-type__type-label dc-contract-type__type-label--multipliers">
                                                                                            <div>Multipliers</div>
                                                                                            <div className="dc-contract-type__type-label-trade-param">Up x50</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dc-progress-slider">
                                                                                <span className="dc-text dc-progress-slider__remaining-time" style={{
                                                                                    "--text-size": "var(--text-size-xxxs)",
                                                                                    "--text-color": "var(--text-general)",
                                                                                    "--text-lh": "var(--text-lh-m)",
                                                                                    "--text-weight": "var(--text-weight-normal)",
                                                                                    "--text-align": "var(--text-align-left)"
                                                                                }}>
                                                                                    <div className="dc-remaining-time">7 días 01:21:56</div>
                                                                                </span>
                                                                                <div className="dc-progress-slider__track">
                                                                                    <div className="dc-progress-slider__line dc-progress-slider__line--green" style={{ width: "98%" }}>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span className="dc-text dc-currency-badge" style={{
                                                                                "--text-size": "var(--text-size-xxxs)"
                                                                                , "--text-color": "var(--text-colored-background)",
                                                                                "--text-lh": "var(--text-lh-unset)",
                                                                                "--text-weight": "var(--text-weight-bold)",
                                                                                "--text-align": "var(--text-align-left)"
                                                                            }}>USD</span>
                                                                            <div className="dc-contract-card-items-wrapper" data-testid="dt_multiplier_card_body">
                                                                                <div className="dc-contract-card-item dc-contract-card__stake">
                                                                                    <div className="dc-contract-card-item__header">Costo del contrato:</div>
                                                                                    <div className="dc-contract-card-item__body">
                                                                                        <span data-testid="dt_span">10.00 </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="dc-contract-card-item dc-contract-card__current-stake">
                                                                                    <div className="dc-contract-card-item__header">Valor del contrato:</div>
                                                                                    <div className="dc-contract-card-item__body">
                                                                                        <div className="dc-contract-card--profit">
                                                                                            <span data-testid="dt_span">11.63 </span>
                                                                                        </div>
                                                                                        <div className="dc-contract-card__indicative--movement" data-testid="dt_arrow_indicator">

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="dc-contract-card-item dc-contract-card__deal-cancel-fee dc-contract-card__deal-cancel-fee__disabled">
                                                                                    <div className="dc-contract-card-item__header">Cuota por cancelación de contrato:</div>
                                                                                    <div className="dc-contract-card-item__body">-</div>
                                                                                </div>
                                                                                <div className="dc-contract-card-item dc-contract-card__buy-price">
                                                                                    <div className="dc-contract-card-item__header">Inversión:</div>
                                                                                    <div className="dc-contract-card-item__body">
                                                                                        <span data-testid="dt_span">10.00 </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="dc-contract-card__limit-order-info">
                                                                                    <div className="dc-contract-card-item dc-contract-card__take-profit">
                                                                                        <div className="dc-contract-card-item__header">Take profit:</div>
                                                                                        <div className="dc-contract-card-item__body">
                                                                                            <span data-testid="dt_span">4.00 </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dc-contract-card-item dc-contract-card__stop-loss">
                                                                                        <div className="dc-contract-card-item__header">Stop loss:</div>
                                                                                        <div className="dc-contract-card-item__body">
                                                                                            <strong>-</strong>
                                                                                            <span data-testid="dt_span">3.00 </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <button className="dc-contract-card-dialog-toggle">

                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dc-contract-card-item dc-contract-card-item__total-profit-loss">
                                                                                <div className="dc-contract-card-item__header">Ganancia/pérdida total:</div>
                                                                                <div className="dc-contract-card-item__body dc-contract-card-item__body--profit">
                                                                                    <span data-testid="dt_span">1.63 </span>
                                                                                    <div className="dc-contract-card__indicative--movement" data-testid="dt_arrow_indicator">

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <div className="dc-contract-card-item__footer">
                                                                            <div className="dc-contract-card__sell-button dc-contract-card__sell-button--positions">
                                                                                <button id="dc_contract_card_258294919888_button" className="dc-btn dc-btn--secondary dc-btn--sell" tabIndex="0" type="submit">
                                                                                    <span className="dc-text dc-btn__text" style={{
                                                                                        "--text-size": "var(--text-size-xs)",
                                                                                        "--text-color": "var(--text-general)",
                                                                                        "--text-lh": "var(--text-lh-m)",
                                                                                        "--text-weight": "var(--text-weight-bold)",
                                                                                        "--text-align": "var(--text-align-center)"
                                                                                    }}>{t('Close')}</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const OpenPositionsGrid = () => {
    const { t } = useTranslation();
    const positions = useRecoilValue(positionsAtom);
    const authToken = useRecoilValue(authTokenAtom);
    const [filteredPositions, setFilteredPositions] = useState([]);
    const [totals, setTotals] = useState({});
    const [filter, setFilter] = useState({ symbol: '', type: '' });
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [changes, setChanges] = useState({}); // Cambios entre renderizaciones

    const columns = [
        { key: 'id', label: t('ID') },
        { key: 'symbol', label: t('Symbol') },
        { key: 'type', label: t('Type') },
        { key: 'open_price', label: t('Open Price') },
        { key: 'current_price', label: t('Current Price') },
        { key: 'time', label: t('Time') },
        { key: 'volume', label: t('Volume') },
        { key: 'tp', label: t('Take Profit') },
        { key: 'sl', label: t('Stop Loss') },
        { key: 'profit', label: t('Profit') },
        { key: 'action', label: t('Action') },
    ];

    useEffect(() => {
        updateChanges();
        applyFilters();
    }, [positions]);

    const updateChanges = () => {
        const newChanges = {};
        positions.forEach((position) => {
            const prevProfit = changes[position.id]?.profit || 0;
            if (position.profit > prevProfit) {
                newChanges[position.id] = { profit: position.profit, change: 'up' };
            } else if (position.profit < prevProfit) {
                newChanges[position.id] = { profit: position.profit, change: 'down' };
            } else {
                newChanges[position.id] = { profit: position.profit, change: 'no-change' };
            }
        });
        setChanges(newChanges);
    };

    const applyFilters = () => {
        let result = [...positions];

        // Filtrar por symbol
        if (filter.symbol) {
            result = result.filter((pos) =>
                pos.symbol.toLowerCase().includes(filter.symbol.toLowerCase())
            );
        }

        // Filtrar por type
        if (filter.type) {
            result = result.filter((pos) => pos.type.toString() === filter.type);
        }

        // Ordenar
        if (sortConfig.key) {
            result.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }

        setFilteredPositions(result);
        calculateTotals(result);
    };

    const calculateTotals = (data) => {
        const totals = {
            profit: 0,
        };

        data.forEach((position) => {
            totals.profit += position.profit || 0;
        });

        setTotals(totals);
    };

    const handleClosePosition = async (position) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(
                `${apiUrl}/market-data/orders/close/${position.id}/`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                }
            );
            console.log(t('Position closed successfully.'), response.data);
            alert(`${t('Position closed successfully.')}`);
        } catch (error) {
            const errorMessage = error.response?.data?.error || error.message;
            console.error(t('Error closing position'), errorMessage);
            alert(`${t('Error closing position')}: ${errorMessage}`);
        }
    };

    const updateFilter = (key, value) => {
        setFilter((prev) => ({ ...prev, [key]: value }));
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    return (
        <div className="grid-container">
            <div className="grid-filters">
                <input
                    type="text"
                    placeholder={t('Filter by symbol')}
                    value={filter.symbol}
                    onChange={(e) => updateFilter('symbol', e.target.value)}
                />
                <select
                    value={filter.type}
                    onChange={(e) => updateFilter('type', e.target.value)}
                >
                    <option value="">{t('Filter by type')}</option>
                    <option value="0">{t('Buy')}</option>
                    <option value="1">{t('Sell')}</option>
                </select>
                {/* <button onClick={handleExport} className="export-button">
                    <i className="bi bi-download"></i> Export Open Positions
                </button> */}
                <ExportButtonWithProgress data={positions} fileName="OpenPositions"/>
            </div>

            <div className="grid-header">
                {columns.map((col) => (
                    <div key={col.key} onClick={() => handleSort(col.key)}>
                        {col.label}
                        {sortConfig.key === col.key &&
                            (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                    </div>
                ))}
            </div>

            <div className="grid-content">
                {filteredPositions.map((position) => (
                    <div className="grid-row" key={position.id}>
                        {columns.map((col) => (
                            <div
                                key={col.key}
                                className={
                                    col.key === 'profit'
                                        ? position.profit > 0
                                            ? 'profit-positive'
                                            : 'profit-negative'
                                        : ''
                                }
                                data-tooltip-id={`tooltip-${position.id}-${col.key}`}
                                data-tooltip-content={position[col.key]}
                            >
                                {col.key === 'action' ? (
                                    <button
                                        onClick={() => handleClosePosition(position)}
                                        className="close-button"
                                    >
                                        {t('Close')}
                                    </button>
                                ) : col.key === 'current_price' ? (
                                    <div>
                                        {position[col.key]}{' '}
                                        {position.current_price > position.open_price ? (
                                            <i className="bi bi-arrow-up text-success"></i>
                                        ) : position.current_price < position.open_price ? (
                                            <i className="bi bi-arrow-down text-danger"></i>
                                        ) : null}
                                    </div>
                                ) : col.key === 'profit' ? (
                                    <div>
                                        {position[col.key]}{' '}
                                        {changes[position.id]?.change === 'up' ? (
                                            <i className="bi bi-arrow-up text-success"></i>
                                        ) : changes[position.id]?.change === 'down' ? (
                                            <i className="bi bi-arrow-down text-danger"></i>
                                        ) : null}
                                    </div>
                                ) : (
                                    position[col.key]
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {filteredPositions.map((position) =>
                columns.map((col) => (
                    <Tooltip
                        key={`tooltip-${position.id}-${col.key}`}
                        id={`tooltip-${position.id}-${col.key}`}
                        place="bottom"
                        className="custom-tooltip"
                    />
                ))
            )}
            <div className="grid-footer">
                {columns.map((col) => (
                    <div key={col.key}>
                        {totals[col.key] !== undefined ? totals[col.key].toFixed(2) : ''}
                    </div>
                ))}
            </div>
        </div>
    );
};
const PositionList = () => {
    const { t } = useTranslation(); // Hook de traducción
    const [filter, setFilter] = useState({
        user: '',
        symbol: '',
        direction: '',
    });


    return (
        <div id="dt_positions_drawer" className="positions-drawer positions-drawer--open">
            <div className="positions-drawer__header">
                <span className="dc-text" style={{
                    "--text-size": "var(--text-size-xs)",
                    "--text-color": "var(--text-prominent)",
                    "--text-lh": "var(--text-lh-m)",
                    "--text-weight": "var(--text-weight-bold)",
                    "--text-align": "var(--text-align-left)"
                }}>{t('Open Positions')}</span>
                <div id="dt_positions_drawer_close_icon" className="positions-drawer__icon-close">
                    <i className="bi bi-dash-lg"></i>
                </div>
            </div>
            <PositionListDrawer></PositionListDrawer>
            <div className="positions-drawer__footer">
                <a className="dc-btn dc-btn--secondary dc-btn__large" id="dt_positions_drawer_report_button" href="/reports">
                    <span className="dc-text" style={{
                        "--text-size": "var(--text-size-xs)",
                        "--text-color": "var(--text-general)",
                        "--text-lh": "var(--text-lh-m)",
                        "--text-weight": "var(--text-weight-bold)",
                        "--text-align": "var(--text-align-left)"
                    }}>{t('Go to Reports')}</span>
                </a>
            </div>
        </div>
    );
};

export { PositionList, PositionListDrawer, OpenPositionsGrid as PositionTable };
