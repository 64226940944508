import React, { useState } from 'react';

const NumericInputField = ({
    inputProps,
    minText,
    maxText,
    minValue,
    maxValue,
    step = 1,
    digits=2,
    value,
    onChange,
}) => {
    const handleIncrement = () => {
        const newValue = parseFloat(value) + parseFloat(step);
        onChange(Math.min(newValue, parseFloat(maxValue)).toFixed(digits));
    };

    const handleDecrement = () => {
        const newValue = parseFloat(value) - parseFloat(step);
        onChange(Math.max(newValue, parseFloat(minValue)).toFixed(digits));
    };

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (/^\d*\.?\d*$/.test(newValue) || newValue === '') {
            onChange(newValue);
        }
    };

    return (
        <>
            <div className="dc-input-field">
                <span className="trade-container__tooltip dc-tooltip" data-tooltip-pos="left">
                    <div className="dc-input-wrapper">
                        <button
                            className="dc-btn dc-input-wrapper__button dc-input-wrapper__button--increment"
                            tabIndex="-1"
                            type="button"
                            aria-label="Increment value"
                            onClick={handleIncrement}
                        >
                            <span
                                className="dc-text dc-btn__text"
                                style={{
                                    '--text-size': 'var(--text-size-xs)',
                                    '--text-color': 'var(--text-general)',
                                    '--text-lh': 'var(--text-lh-m)',
                                    '--text-weight': 'var(--text-weight-bold)',
                                    '--text-align': 'var(--text-align-center)',
                                }}
                            >
                                <i className="bi bi-plus-lg"></i>
                            </span>
                        </button>
                        <button
                            className="dc-btn dc-input-wrapper__button dc-input-wrapper__button--decrement"
                            tabIndex="-1"
                            type="button"
                            aria-label="Decrement value"
                            onClick={handleDecrement}
                        >
                            <span
                                className="dc-text dc-btn__text"
                                style={{
                                    '--text-size': 'var(--text-size-xs)',
                                    '--text-color': 'var(--text-general)',
                                    '--text-lh': 'var(--text-lh-m)',
                                    '--text-weight': 'var(--text-weight-bold)',
                                    '--text-align': 'var(--text-align-center)',
                                }}
                            >
                                <i className="bi bi-dash-lg"></i>
                            </span>
                        </button>
                        <div>
                            <input
                                {...inputProps}
                                className="dc-input-wrapper__input input trade-container__input"
                                value={value}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </span>
            </div>
            {(minText || maxText) && (
                <section className="trade-container__stake-field">
                    {minText && (
                        <p
                            className="dc-text trade-container__stake-field--min"
                            style={{
                                '--text-size': 'var(--text-size-xxxs)',
                                '--text-color': 'var(--text-general)',
                                '--text-lh': 'var(--text-lh-s)',
                                '--text-weight': 'var(--text-weight-normal)',
                                '--text-align': 'var(--text-align-left)',
                            }}
                        >
                            {minText}
                            <span data-testid="dt_span">{minValue}</span>
                        </p>
                    )}
                    {maxText && (
                        <p
                            className="dc-text trade-container__stake-field--max"
                            style={{
                                '--text-size': 'var(--text-size-xxxs)',
                                '--text-color': 'var(--text-general)',
                                '--text-lh': 'var(--text-lh-s)',
                                '--text-weight': 'var(--text-weight-normal)',
                                '--text-align': 'var(--text-align-left)',
                            }}
                        >
                            {maxText}
                            <span data-testid="dt_span">{maxValue}</span>
                        </p>
                    )}
                </section>
            )}
        </>
    );
};

export default NumericInputField;
