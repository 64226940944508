import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                "Logo": "Logo",
                "Trading Terminal": "Trading Terminal",
                "Reports": "Reports",
                "Funds": "Funds",
                "Deposit": "Deposit",
                "Real Accounts": "Real Accounts",
                "Demo Accounts": "Demo Accounts",
                "Total Assets": "Total Assets",
                "Manage Accounts": "Manage Accounts",
                "Error fetching reCAPTCHA key": "Error fetching reCAPTCHA key",
                "Volume": "Volume",
                "Symbol or timeframe is not selected": "Symbol or timeframe is not selected",
                "Error updating chart": "Error updating chart",
                "ID": "ID",
                "Symbol": "Symbol",
                "Type": "Type",
                "Open Price": "Open Price",
                "Current Price": "Current Price",
                "Time": "Time",
                "Take Profit": "Take Profit",
                "Stop Loss": "Stop Loss",
                "Profit": "Profit",
                "Action": "Action",
                "Close": "Close",
                "Position closed successfully.": "Position closed successfully.",
                "Error closing position": "Error closing position",
                "The backend is not available. Please try again later": "The backend is not available. Please try again later",
                "Disconnected": "Disconnected"
            }
        },
        es: {
            translation: {
                "Logo": "Logo",
                "Trading Terminal": "Terminal de Trading",
                "Reports": "Informes",
                "Funds": "Fondos",
                "Deposit": "Depositar",
                "Real Accounts": "Cuentas Reales",
                "Demo Accounts": "Cuentas Demo",
                "Total Assets": "Total de Activos",
                "Manage Accounts": "Gestionar Cuentas",
                "Error fetching reCAPTCHA key": "Error obteniendo la clave de reCAPTCHA",
                "Volume": "Volumen",
                "Symbol or timeframe is not selected": "No se seleccionó símbolo o marco temporal",
                "Error updating chart": "Error al actualizar el gráfico",
                "ID": "ID",
                "Symbol": "Símbolo",
                "Type": "Tipo",
                "Open Price": "Precio de Apertura",
                "Current Price": "Precio Actual",
                "Time": "Hora",
                "Take Profit": "Tomar Ganancia",
                "Stop Loss": "Detener Pérdida",
                "Profit": "Ganancia",
                "Action": "Acción",
                "Close": "Cerrar",
                "Position closed successfully.": "Posición cerrada exitosamente.",
                "Error closing position": "Error al cerrar la posición",
                "The backend is not available. Please try again later": "El backend no está disponible. Intenta nuevamente más tarde",
                "Disconnected": "Desconectado"
            }
        }
    },
    lng: "en", // Idioma predeterminado
    fallbackLng: "en", // Idioma de respaldo
    interpolation: {
        escapeValue: false // React ya maneja el escape de valores
    }
});

export default i18n;
