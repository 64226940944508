import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { authTokenAtom, selectedAccountAtom, ordersEndpointAtom } from "../atoms"; // Importa el token desde el estado
import axios from "axios";
import "../styles/OrdersGrid.css"; // Asegúrate de incluir los estilos específicos para OrdersGrid
import { Tooltip } from "react-tooltip";
import ExportButtonWithProgress from "./ExportButtonWithProgress";

const OrdersGrid = () => {
    const { t } = useTranslation();
    const authToken = useRecoilValue(authTokenAtom);
    const selectedAccount = useRecoilValue(selectedAccountAtom);
    const ordersEndpoint = useRecoilValue(ordersEndpointAtom);
    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 1, pageSize: 20 });
    const [filter, setFilter] = useState({
        symbol: "",
        type: "",
        state: "",
        setup_time_from: "",
        setup_time_to: "",
    });

    const columns = [
        { key: "id", label: t("ID") },
        { key: "symbol_name", label: t("Symbol") },
        { key: "type_name", label: t("Type") },
        { key: "state_name", label: t("State") },
        { key: "time_setup", label: t("Setup Time") },
        { key: "volume_initial", label: t("Initial Volume") },
        { key: "price_open", label: t("Open Price") },
        { key: "price_stop_limit", label: t("Stop Limit Price") },
        { key: "sl", label: t("Stop Loss") },
        { key: "tp", label: t("Take Profit") },
        { key: "comment", label: t("Comment") },
    ];

    useEffect(() => {
        if (selectedAccount) {
            fetchOrders(pagination.currentPage);
        }
    }, [pagination.currentPage, filter, selectedAccount]);

    const fetchOrders = async (page) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${apiUrl}/market-data/orders/`, {
                headers: { Authorization: `Bearer ${authToken}` },
                params: {
                    ...filter,
                    page,
                    page_size: pagination.pageSize,
                    account_id: selectedAccount.id,
                },
            });
            setOrders(response.data.results);
            setPagination((prev) => ({
                ...prev,
                totalPages: Math.ceil(response.data.count / prev.pageSize),
            }));
        } catch (error) {
            console.error(t("Error fetching orders"), error);
        }
    };

    const updateFilter = (key, value) => {
        setFilter((prev) => ({ ...prev, [key]: value }));
    };

    const goToPage = (page) => {
        if (page >= 1 && page <= pagination.totalPages) {
            setPagination((prev) => ({ ...prev, currentPage: page }));
        }
    };



    return (
        <div className="orders-grid-container">
            {/* Filtros */}
            <div className="grid-filters">
                <input
                    type="text"
                    placeholder={t("Filter by symbol")}
                    value={filter.symbol}
                    onChange={(e) => updateFilter("symbol", e.target.value)}
                />
                <select
                    value={filter.type}
                    onChange={(e) => updateFilter("type", e.target.value)}
                >
                    <option value="">{t("Filter by type")}</option>
                    <option value="0">{t("Buy")}</option>
                    <option value="1">{t("Sell")}</option>
                </select>
                <select
                    value={filter.state}
                    onChange={(e) => updateFilter("state", e.target.value)}
                >
                    <option value="">{t("Filter by state")}</option>
                    <option value="0">{t("Pending")}</option>
                    <option value="1">{t("Completed")}</option>
                    <option value="2">{t("Cancelled")}</option>
                </select>
                <input
                    type="date"
                    placeholder={t("From")}
                    value={filter.setup_time_from}
                    onChange={(e) => updateFilter("setup_time_from", e.target.value)}
                />
                <input
                    type="date"
                    placeholder={t("To")}
                    value={filter.setup_time_to}
                    onChange={(e) => updateFilter("setup_time_to", e.target.value)}
                />
                <ExportButtonWithProgress apiUrl={ordersEndpoint} params={filter} fileName="Orders" />
            </div>

            {/* Encabezado */}
            <div className="grid-header">
                {columns.map((col) => (
                    <div key={col.key}>{col.label}</div>
                ))}
            </div>

            {/* Contenido */}
            <div className="grid-content">
                {orders.map((order) => (
                    <div className="grid-row" key={order.id}>
                        {/* {columns.map((col) => (
                            <div key={col.key}>{order[col.key]}</div>
                        ))} */}
                        {columns.map((col) => (
                            <div
                                key={col.key}
                                className="grid-cell"
                                data-tooltip-id={`tooltip-${order.id}-${col.key}`} // Identificador único
                                data-tooltip-content={order[col.key]} // Contenido dinámico
                            >
                                {order[col.key]}
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {/* Paginación */}
            <div className="grid-pagination">
                <button
                    onClick={() => goToPage(pagination.currentPage - 1)}
                    disabled={pagination.currentPage === 1}
                >
                    {t("Previous")}
                </button>
                <span>
                    {t("Page")} {pagination.currentPage} {t("of")} {pagination.totalPages}
                </span>
                <button
                    onClick={() => goToPage(pagination.currentPage + 1)}
                    disabled={pagination.currentPage === pagination.totalPages}
                >
                    {t("Next")}
                </button>
            </div>
            {orders.map((order) =>
                columns.map((col) => (
                    <Tooltip
                        key={`tooltip-${order.id}-${col.key}`}
                        id={`tooltip-${order.id}-${col.key}`}
                        place="bottom"
                        className="custom-tooltip"
                    />
                ))
            )}
        </div>
    );
};

export default OrdersGrid;
