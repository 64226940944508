import React, { useState, useEffect, useContext, useRef } from "react";
import { useRecoilValue, useRecoilState } from 'recoil';
import { symbolsAtom, symbolIndustriesAtom, symbolSectorsAtom, selectedSymbolAtom } from '../atoms';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MenuItem from "./MenuItem";
import MenuSector from "./MenuSector";
import MenuIndustry from "./MenuIndustry";
import MenuGroup from "./MenuGroup";
import SearchInput from "./SearchInput";
import Dialog from "./Dialog";
import SymbolInfo from "./SymbolInfo";
import { AuthenticationContext } from "../contexts/AuthenticationContext";

const Menu = ({ onSymbolSelect }) => {
    const { t } = useTranslation(); // Hook de traducción
    const { authTokens } = useContext(AuthenticationContext);
    const [enterDone, setEnterDone] = useState(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSymbol, setSelectedSymbol] = useRecoilState(selectedSymbolAtom);

    const menuRef = useRef(null);

    const sectors = useRecoilValue(symbolSectorsAtom);
    const industries = useRecoilValue(symbolIndustriesAtom);
    const symbols = useRecoilValue(symbolsAtom);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                setIsLoading(true);
                if (!selectedSymbol && symbols.length > 0) {
                    localStorage.setItem('selectedSymbol', JSON.stringify(symbols[0]));
                    setSelectedSymbol(symbols[0]);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error loading menu data:', error);
                setIsLoading(false);
            }
        };

        if (authTokens) {
            fetchMenuData();
        }
    }, [authTokens]);

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm.toLowerCase());
    };

    const handleSymbolSelect = (symbol) => {
        localStorage.setItem('selectedSymbol', JSON.stringify(symbol));
        setSelectedSymbol(symbol);
        onSymbolSelect(symbol);
        setEnterDone(prev => !prev);
    };

    const handleClick = () => {
        setEnterDone(prev => !prev);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setEnterDone(false);
        }
    };

    useEffect(() => {
        if (enterDone) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [enterDone]);

    const filteredSymbols = symbols.filter(symbol =>
        symbol.name.toLowerCase().includes(searchTerm)
    );

    if (isLoading) {
        return <div>{t("Loading menu")}</div>;
    }

    return (
        <div ref={menuRef} className="ciq-menu ciq-enabled cq-chart-title stx-show cq-symbols-display">
            <div onClick={handleClick} className="cq-menu-btn">
                <div className="cq-symbol-select-btn">
                    <div className="cq-symbol-select-btn__container">
                        <span className="ic-icon"></span>
                        {selectedSymbol && <SymbolInfo />}
                    </div>
                    <span className="ic-icon cq-symbol-dropdown">
                        {enterDone ? (
                            <i className="bi bi-chevron-up"></i>
                        ) : (
                            <i className="bi bi-chevron-down"></i>
                        )}
                    </span>
                </div>
            </div>
            <Dialog className={classNames("cq-menu-dropdown", { "cq-menu-dropdown-enter-done": enterDone })}>
                <div className="sc-mcd" style={{ height: `${windowHeight - 180}px` }}>
                    <div className="sc-mcd__tabs">
                        <div className="sc-mcd__tabs__head">
                            {t("Symbols")}
                        </div>
                        <div className="sc-mcd__tabs__body">
                            <div className="sc-mcd__filter">
                                {sectors.map(sector => (
                                    <MenuSector key={sector.id} id={sector.id} sectorName={sector.name}
                                        selected={selectedSymbol && selectedSymbol.industry.sector.id === sector.id} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="sc-mcd__content">
                        <div className="sc-mcd__content__head">
                            <SearchInput onSearch={handleSearch} />
                        </div>
                        <div className="sc-mcd__content__body">
                            <div className="sc-scrollbar sc-scrollbar--auto-hide">
                                {industries.map(industry => (
                                    <MenuIndustry key={industry.id} id={industry.id} industryName={industry.name}>
                                        <MenuGroup groupName={t("Synthetic Symbols")}>
                                            {filteredSymbols
                                                .filter(symbol => symbol.industry.id === industry.id && symbol.is_synthetic)
                                                .map(symbol => (
                                                    <MenuItem key={symbol.id} id={symbol.id} title={symbol.name}
                                                        onClick={() => handleSymbolSelect(symbol)}
                                                        selected={selectedSymbol && selectedSymbol.id === symbol.id} />
                                                ))}
                                        </MenuGroup>

                                        <MenuGroup groupName={t("Non-Synthetic Symbols")}>
                                            {filteredSymbols
                                                .filter(symbol => symbol.industry.id === industry.id && !symbol.is_synthetic)
                                                .map(symbol => (
                                                    <MenuItem key={symbol.id} id={symbol.id} title={symbol.name}
                                                        onClick={() => handleSymbolSelect(symbol)}
                                                        selected={selectedSymbol && selectedSymbol.id === symbol.id} />
                                                ))}
                                        </MenuGroup>
                                    </MenuIndustry>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Menu;
