import axios from 'axios';

let isOnline = true; // Estado inicial del API

// Configurar interceptor de solicitudes
axios.interceptors.request.use(
  (config) => {
    if (!isOnline) {
      // Bloquear la solicitud lanzando un error si el API está desconectado
      return Promise.reject({ message: 'API no disponible' });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Función para actualizar el estado de conexión
export const setApiOnlineStatus = (status) => {
  isOnline = status;
};
