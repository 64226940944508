import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción
import { accountsAtom } from '../atoms';
import AccountSelector from './AccountSelector';

const Header = () => {
    const { t } = useTranslation(); // Hook de traducción
    const accounts = useRecoilValue(accountsAtom);

    const { demoAccounts, realAccounts } = accounts.reduce(
        (result, account) => {
            if (account.trade_mode === 0) {
                result.demoAccounts.push(account);
            } else if (account.trade_mode === 2) {
                result.realAccounts.push(account);
            }
            return result;
        },
        { demoAccounts: [], realAccounts: [] }
    );

    return (
        <header className="header">
            <div className="header__menu-items">
                <div className="header__menu-left">
                    <div className="header__menu-left-logo">
                        <a
                            href="#"
                            rel="noopener noreferrer"
                            target="_blank"
                        >{t('Logo')}</a>
                    </div>
                    <div className="header__divider"></div>
                    <div
                        data-testid="dt_traders_hub_home_button"
                        className="traders-hub-header__tradershub"
                    >
                        <div className="traders-hub-header__tradershub--home-logo">
                        </div>
                        <span style={{
                            '--text-size': 'var(--text-size-s)',
                            '--text-color': 'var(--text-general)',
                            '--text-lh': 'var(--text-lh-m)',
                            '--text-weight': 'var(--text-weight-normal)',
                            '--text-align': 'var(--text-align-left)'
                        }}
                            className="dc-text traders-hub-header__tradershub--text"
                        >
                            <a className="header__menu-link" href="/">{t('Trading Terminal')}</a>
                        </span>
                    </div>
                    <div className="header__menu-links">
                        <span
                            data-testid="dt_span"
                            className="header__menu-link--active__link-wrapper"
                        >
                            <a
                                className="header__menu-link"
                                id="dt_reports_tab"
                                href="/reports"
                            >
                                <span
                                    className="dc-text header__menu-link-text"
                                    title={t('Reports')}
                                >
                                    {t('Reports')}
                                </span>
                            </a>
                        </span>
                        <span
                            data-testid="dt_span"
                            className="header__menu-link--active__link-wrapper"
                        >
                            <a
                                className="header__menu-link"
                                href="/templates"
                            >
                                <span
                                    className="dc-text header__menu-link-text"
                                    title={t('Templates')}
                                >
                                    {t('Bot Templates')}
                                </span>
                            </a>
                        </span>
                    </div>
                </div>
                <div className="header__menu-right">
                    <div className="header__menu--dtrader--separator--account">
                        <div className="header__menu--dtrader--separator"></div>
                    </div>
                    <div
                        id="dt_core_header_acc-info-container"
                        className="acc-info__container"
                    >
                        <div className="notifications-toggle">
                            <div className="" data-testid="dt_popover_wrapper">
                                <div className="dc-popover">
                                    <div className="dc-popover__target">
                                        <i className="dc-popover__target__icon"></i>
                                        <div className="notifications-toggle__icon-wrapper">
                                            <i className="bi bi-bell dc-text" style={{
                                                fontSize: 'var(--text-size-sm)',
                                                '--text-color': 'var(--text-general)',
                                                '--text-lh': 'var(--text-lh-m)',
                                                '--text-weight': 'var(--text-weight-normal)',
                                                '--text-align': 'var(--text-align-left)'
                                            }}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="" data-testid="dt_popover_wrapper">
                            <div className="dc-popover">
                                <div className="dc-popover__target">
                                    <i className="dc-popover__target__icon"></i>
                                    <span data-testid="dt_span">
                                        <a
                                            className="account-settings-toggle dc-text"
                                            style={{
                                                '--text-size': 'var(--text-size-s)',
                                                '--text-color': 'var(--text-general)',
                                                '--text-lh': 'var(--text-lh-m)',
                                                '--text-weight': 'var(--text-weight-normal)',
                                                '--text-align': 'var(--text-align-left)'
                                            }}
                                            href="/account/personal-details"
                                        >
                                            <i className="bi bi-person-circle" style={{ fontSize: '2rem' }}></i>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <AccountSelector />
                        <button
                            className="dc-btn dc-btn__effect dc-btn--primary acc-info__button"
                            tabIndex="0"
                            type="submit"
                        >
                            <span style={{
                                '--text-size': 'var(--text-size-xs)',
                                '--text-color': 'var(--text-general)',
                                '--text-lh': 'var(--text-lh-m)',
                                '--text-weight': 'var(--text-weight-bold)',
                                '--text-align': 'var(--text-align-center)'
                            }}
                                className="dc-text dc-btn__text"
                            >
                                {t('Deposit')}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
