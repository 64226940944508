import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import axios from "axios";
import { authTokenAtom, dealsEndpointAtom, selectedAccountAtom } from "../atoms";
import "../styles/DealsGrid.css";
import ExportButtonWithProgress from "./ExportButtonWithProgress";

const DealsGrid = () => {
    const { t } = useTranslation();
    const authToken = useRecoilValue(authTokenAtom);
    const selectedAccount = useRecoilValue(selectedAccountAtom);
    const dealsEndpoint = useRecoilValue(dealsEndpointAtom);

    const [deals, setDeals] = useState([]);
    const [filter, setFilter] = useState({ symbol: "", type: "", start_time: "", end_time: "" });
    const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 1, pageSize: 20 });

    const columns = [
        { key: "id", label: t("ID") },
        { key: "symbol", label: t("Symbol") },
        { key: "time", label: t("Time") },
        { key: "type", label: t("Type") },
        { key: "entry", label: t("Entry") },
        { key: "reason", label: t("Reason") },
        { key: "volume", label: t("Volume") },
        { key: "price", label: t("Price") },
        { key: "price_close", label: t("Close Price") },
        { key: "commission", label: t("Commission") },
        { key: "swap", label: t("Swap") },
        { key: "profit", label: t("Profit") },
        { key: "fee", label: t("Fee") },
        { key: "sl", label: t("Stop Loss") },
        { key: "tp", label: t("Take Profit") },
    ];

    useEffect(() => {
        fetchDeals();
    }, [filter, pagination.currentPage, selectedAccount]);

    const fetchDeals = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${apiUrl}/market-data/deals/`, {
                headers: { Authorization: `Bearer ${authToken}` },
                params: {
                    account_id: selectedAccount.id,
                    ...filter,
                    page: pagination.currentPage,
                    page_size: pagination.pageSize,
                },
            });
            setDeals(response.data.results);
            setPagination((prev) => ({
                ...prev,
                totalPages: Math.ceil(response.data.count / pagination.pageSize),
            }));
        } catch (error) {
            console.error(t("Error fetching deals"), error);
        }
    };

    const updateFilter = (key, value) => {
        setFilter((prev) => ({ ...prev, [key]: value }));
    };

    const goToPage = (page) => {
        if (page >= 1 && page <= pagination.totalPages) {
            setPagination((prev) => ({ ...prev, currentPage: page }));
        }
    };

    return (
        <div className="deals-grid-container">
            {/* Filtros */}
            <div className="grid-filters">
                <input
                    type="text"
                    placeholder={t("Filter by symbol")}
                    value={filter.symbol}
                    onChange={(e) => updateFilter("symbol", e.target.value)}
                />
                <select
                    value={filter.type}
                    onChange={(e) => updateFilter("type", e.target.value)}
                >
                    <option value="">{t("Filter by type")}</option>
                    <option value="0">{t("Buy")}</option>
                    <option value="1">{t("Sell")}</option>
                </select>
                <input
                    type="date"
                    placeholder={t("Start Time")}
                    value={filter.start_time}
                    onChange={(e) => updateFilter("start_time", e.target.value)}
                />
                <input
                    type="date"
                    placeholder={t("End Time")}
                    value={filter.end_time}
                    onChange={(e) => updateFilter("end_time", e.target.value)}
                />
                <ExportButtonWithProgress apiUrl={dealsEndpoint} params={filter} fileName="Deals" />
            </div>

            {/* Encabezado */}
            <div className="grid-header">
                {columns.map((col) => (
                    <div key={col.key}>{col.label}</div>
                ))}
            </div>

            {/* Contenido */}
            <div className="grid-content">
                {deals.map((deal) => (
                    <div className="grid-row" key={deal.id}>
                        {columns.map((col) => (
                            <div
                                key={col.key}
                                className={
                                    col.key === 'profit'
                                        ? deal.profit > 0
                                            ? 'profit-positive'
                                            : 'profit-negative'
                                        : ''
                                }
                                data-tooltip-id={`tooltip-${deal.id}-${col.key}`}
                                data-tooltip-content={deal[col.key]}
                            >
                                {col.key === 'price_close' ? (
                                    <div>
                                        {deal[col.key]}{' '}
                                        {deal.price_close > deal.price ? (
                                            <i className="bi bi-arrow-up text-success"></i>
                                        ) : deal.price_close < deal.price && deal.price_close !== 0 ? (
                                            <i className="bi bi-arrow-down text-danger"></i>
                                        ) : null}
                                    </div>
                                ) : (
                                    deal[col.key]
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {/* Paginación */}
            <div className="grid-pagination">
                <button onClick={() => goToPage(pagination.currentPage - 1)} disabled={pagination.currentPage === 1}>
                    {t("Previous")}
                </button>
                <span>
                    {t("Page")} {pagination.currentPage} {t("of")} {pagination.totalPages}
                </span>
                <button onClick={() => goToPage(pagination.currentPage + 1)} disabled={pagination.currentPage === pagination.totalPages}>
                    {t("Next")}
                </button>
            </div>

            {/* Tooltips */}
            {deals.map((deal) =>
                columns.map((col) => (
                    <Tooltip key={`tooltip-${deal.id}-${col.key}`} id={`tooltip-${deal.id}-${col.key}`} place="bottom" />
                ))
            )}
        </div>
    );
};

export default DealsGrid;
