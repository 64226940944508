import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { authTokenAtom } from "../atoms";
import { useTranslation } from "react-i18next";
import SymbolTemplateForm from "./SymbolTemplateForm"; // Importar el formulario
import "../styles/SymbolTemplateCards.css";

const SymbolTemplateCards = () => {
    const authToken = useRecoilValue(authTokenAtom);
    const apiUrl = process.env.REACT_APP_API_URL; // Base URL
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState(null);
    const { t } = useTranslation();

    const fetchTemplates = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/auto-trader/symbol-templates/`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            setTemplates(response.data);
        } catch (err) {
            setError(t("Failed to fetch symbol templates."));
        } finally {
            setLoading(false);
        }
    };

    const deleteTemplate = async (id) => {
        try {
            await axios.delete(`${apiUrl}/auto-trader/symbol-templates/${id}/`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            setTemplates(templates.filter((template) => template.id !== id));
        } catch (err) {
            console.error(t("Failed to delete template."));
        }
    };

    const handleEdit = (template) => {
        setEditingTemplate(template.id);
        setShowForm(true);
    };

    const handleCreate = () => {
        setEditingTemplate(null);
        setShowForm(true);
    };

    const handleFormClose = () => {
        setShowForm(false);
        setEditingTemplate(null);
        fetchTemplates(); // Refrescar la lista después de guardar
    };

    useEffect(() => {
        fetchTemplates();
    }, [authToken]);

    // if (loading) return <div>{t("Loading...")}</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="cards-container">
            {/* Mostrar las cards */}
            {templates.map((template) => (
                <div className="card" key={template.id}>
                    {/* Botones de acción */}
                    <div className="card-actions">
                        <button className="edit-button" onClick={() => handleEdit(template)}>
                            <i className="bi bi-pencil"></i>
                        </button>
                        <button className="delete-button" onClick={() => deleteTemplate(template.id)}>
                            <i className="bi bi-trash"></i>
                        </button>
                    </div>
                    {/* Contenido principal */}
                    <div className="card-content">
                        <h3>{template.symbol.name}</h3>
                        <p>{t("Timeframe")}: {template.timeframe.name}</p>
                        <p>{t("Order Periods")}: {template.order_periods}</p>
                        <p>{t("Analysis Periods")}: {template.analysis_periods}</p>
                    </div>
                    {/* Profit */}
                    <div
                        className={`card-profit ${template.total_profit >= 0 ? "profit-positive" : "profit-negative"}`}
                    >
                        {t("Profit")}: {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).format(template.total_profit)}
                    </div>
                </div>
            ))}
            {/* Botón para crear un nuevo template */}
            <button className="create-button" onClick={handleCreate}>
                <i className="bi bi-plus-circle"></i> {t("Create New Template")}
            </button>

            {/* Formulario de creación/edición */}
            {showForm && (
                <SymbolTemplateForm
                    templateId={editingTemplate}
                    onClose={handleFormClose}
                    onSave={handleFormClose}
                />
            )}
        </div>
    );
};

export default SymbolTemplateCards;
