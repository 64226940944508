import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from 'axios';
import {
    authTokenAtom, timeframesAtom, symbolSectorsAtom, symbolIndustriesAtom, symbolsAtom,
    syntheticSymbolsAtom, ticksAtom, accountsAtom, ordersAtom, dealsAtom, positionsAtom, candlesAtom,
    selectedSymbolAtom, selectedTimeframeAtom, symbolInfoTickAtom,
    selectedAccountAtom, positionsEndpointAtom, ordersEndpointAtom, dealsEndpointAtom
} from '../atoms';

const DataFetcher = () => {
    const authToken = useRecoilValue(authTokenAtom);
    const [selectedSymbol, setSelectedSymbol] = useRecoilState(selectedSymbolAtom);
    const [selectedAccount, setSelectedAccount] = useRecoilState(selectedAccountAtom);
    const selectedTimeframe = useRecoilValue(selectedTimeframeAtom);
    const [timeframes, setTimeframes] = useRecoilState(timeframesAtom);
    const [symbolSectors, setSymbolSectors] = useRecoilState(symbolSectorsAtom);
    const [symbolIndustries, setSymbolIndustries] = useRecoilState(symbolIndustriesAtom);
    const [symbols, setSymbols] = useRecoilState(symbolsAtom);
    const [syntheticSymbols, setSyntheticSymbols] = useRecoilState(syntheticSymbolsAtom);
    const [ticks, setTicks] = useRecoilState(ticksAtom);
    const [accounts, setAccounts] = useRecoilState(accountsAtom);
    const [orders, setOrders] = useRecoilState(ordersAtom);
    const [deals, setDeals] = useRecoilState(dealsAtom);
    const [positions, setPositions] = useRecoilState(positionsAtom);
    const [candles, setCandles] = useRecoilState(candlesAtom);
    const [symbolInfoTick, setSymbolInfoTick] = useRecoilState(symbolInfoTickAtom);
    const [positionsEndpoint, setPositionsEndpoint] = useRecoilState(positionsEndpointAtom);
    const [ordersEndpoint, setOrdersEndpoint] = useRecoilState(ordersEndpointAtom);
    const [dealsEndpoint, setDealsEndpoint] = useRecoilState(dealsEndpointAtom);

    const ongoingRequests = useRef({});
    const intervalRef = useRef(null);
    const isFetching = useRef(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchData = async (endpoint, setState, localStorageKey) => {
        if (ongoingRequests.current[localStorageKey]) {
            return; // No iniciar una nueva solicitud si la anterior aún está en curso
        }
        ongoingRequests.current[localStorageKey] = true;
        try {

            const response = await axios.get(`${apiUrl}/market-data/${endpoint}/?account_id=${selectedAccount?.id ?? 0}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
            });
            const data = response.data;
            localStorage.setItem(localStorageKey, JSON.stringify(data));
            setState(data);
        } catch (error) {
            console.error(`Error fetching data from ${endpoint}:`, error);
        } finally {
            ongoingRequests.current[localStorageKey] = false;
        }
    };

    useEffect(() => {

        fetchData('accounts', setAccounts, 'accounts').then(() => {
            if (!selectedAccount) {
                localStorage.setItem('selectedAccount', JSON.stringify(accounts[0]));
                setSelectedAccount(accounts[0]);
            }
        });

        if (selectedAccount) {
            setPositionsEndpoint(`${apiUrl}/market-data/positions/?account_id=${selectedAccount?.id}`);
            setOrdersEndpoint(`${apiUrl}/market-data/orders/?account_id=${selectedAccount?.id}`);
            setDealsEndpoint(`${apiUrl}/market-data/deals/?account_id=${selectedAccount?.id}`);
        }
        if (!authToken) {
            return; // No realizar consultas si no hay token de autenticación
        }
        const intervals = [];
        fetchData('timeframes', setTimeframes, 'timeframes');
        fetchData('symbol-sectors', setSymbolSectors, 'symbolSectors');
        fetchData('symbol-industries', setSymbolIndustries, 'symbolIndustries');
        fetchData('symbols', setSymbols, 'symbols').then(() => {
            if (!selectedSymbol) {
                localStorage.setItem('selectedSymbol', JSON.stringify(symbols[0]));
                setSelectedSymbol(symbols[0]);
            }
        });
        fetchData('synthetic-symbols', setSyntheticSymbols, 'syntheticSymbols');
        fetchData('accounts', setAccounts, 'accounts').then(() => {
            if (!selectedAccount) {
                localStorage.setItem('selectedAccount', JSON.stringify(accounts[0]));
                setSelectedAccount(accounts[0]);
            }
        });
        fetchData('positions', setPositions, 'positions');

        // Configura intervalos de actualización independientes para cada modelo
        intervals.push(setInterval(() => fetchData('timeframes', setTimeframes, 'timeframes'), 60000));
        intervals.push(setInterval(() => fetchData('symbol-sectors', setSymbolSectors, 'symbolSectors'), 600000));
        intervals.push(setInterval(() => fetchData('symbol-industries', setSymbolIndustries, 'symbolIndustries'), 600000));
        intervals.push(setInterval(() => fetchData('symbols', setSymbols, 'symbols'), 60000));
        intervals.push(setInterval(() => fetchData('synthetic-symbols', setSyntheticSymbols, 'syntheticSymbols'), 60000));
        // intervals.push(setInterval(() => fetchData('ticks', setTicks, 'ticks'), 60000));
        intervals.push(setInterval(() => fetchData('accounts', setAccounts, 'accounts'), 10000));
        // intervals.push(setInterval(() => fetchData('orders', setOrders, 'orders'), 45000));
        // intervals.push(setInterval(() => fetchData('deals', setDeals, 'deals'), 60000));
        intervals.push(setInterval(() => fetchData('positions', setPositions, 'positions'), 500));
        return () => {
            // Limpia todos los intervalos al desmontar el componente
            intervals.forEach(clearInterval);
        };
    }, [authToken, selectedAccount, setTimeframes, setSymbolSectors, setSymbolIndustries, setSymbols, setSyntheticSymbols, setTicks, setAccounts, setOrders, setDeals, setPositions]);

    useEffect(() => {
        if (!authToken || !selectedSymbol || !selectedTimeframe) {
            return; // No configurar el intervalo si no hay valores
        }
        const startFetching = () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            intervalRef.current = setInterval(async () => {
                if (isFetching.current) {
                    return; // No iniciar una nueva solicitud si la anterior aún está en curso
                }

                isFetching.current = true;
                await fetchData(
                    `symbol-info-tick/${selectedSymbol?.name}`,
                    setSymbolInfoTick,
                    'symbolInfoTick'
                );

                const now = Math.floor(Date.parse(symbolInfoTick?.symbol_info_tick.time) / 1000);
                await fetchData(
                    `get-rates-from/${selectedSymbol?.name}/${selectedTimeframe?.name}/${now}/1000`,
                    setCandles,
                    'candles'
                );

                isFetching.current = false;
            }, 500);
        };

        // Ejecuta la función inicial de "fetching" y luego comienza el intervalo
        startFetching();

        return () => {
            // Limpiar el intervalo al desmontar o cuando cambien las dependencias
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };

    }, [authToken, selectedSymbol, selectedTimeframe, symbolInfoTick, setCandles]);

    return null; // No renderiza nada
};

export default DataFetcher;
