import React, { useState, useEffect } from "react";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { authTokenAtom } from "../atoms";
import { useTranslation } from "react-i18next";
import NumericInputField from "./NumericInputField";
import "../styles/SymbolTemplateForm.css";
import Switch from "./Switch";

const SymbolTemplateForm = ({ templateId, onClose, onSave }) => {
    const authToken = useRecoilValue(authTokenAtom);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        symbol: "",
        timeframe: "",
        order_periods: 0,
        analysis_periods: 0,
        tp_buy: 0.0,
        tp_sell: 0.0,
        tp_type: 0,
        sl_buy: 0.0,
        max_sl_buy: -1.0,
        sl_sell: 0.0,
        min_sl_sell: 1.0,
        sl_type: 0,
        volume_order: 0.0,
        volume_delta: 0.0,
        volume_max: 0.0,
        volume_factor: 1.0,
        max_spread: 1,
        max_swap: 0,
        do_sell: false,
        do_buy: false,
        max_concurrent_orders: 10,
        is_active: true,
        analysis_schedule: [],
        orders_schedule: [],
    });

    const [symbols, setSymbols] = useState([]);
    const [timeframes, setTimeframes] = useState([]);
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [accordionState, setAccordionState] = useState({
        instrumentData: true,
        periodConfig: true,
        profitLimits: true,
        lossLimits: true,
        volumeParams: true,
        marketRestrictions: true,
        orderManagement: true,
        stateScheduling: true,
        marketAction: true,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [symbolsResponse, timeframesResponse, daysResponse] = await Promise.all([
                    axios.get(`${apiUrl}/market-data/symbols/`, {
                        headers: { Authorization: `Bearer ${authToken}` },
                    }),
                    axios.get(`${apiUrl}/market-data/timeframes/`, {
                        headers: { Authorization: `Bearer ${authToken}` },
                    }),
                    axios.get(`${apiUrl}/auto-trader/day-of-week/`, {
                        headers: { Authorization: `Bearer ${authToken}` },
                    }),
                ]);
                setSymbols(symbolsResponse.data);
                setTimeframes(timeframesResponse.data);
                setDaysOfWeek(daysResponse.data);
            } catch (err) {
                setError(t("Failed to load form data."));
            }
        };

        fetchData();
    }, [authToken, apiUrl, t]);

    useEffect(() => {
        if (templateId) {
            const fetchTemplate = async () => {
                try {
                    const response = await axios.get(`${apiUrl}/auto-trader/symbol-templates/${templateId}/`, {
                        headers: { Authorization: `Bearer ${authToken}` },
                    });
                    setFormData(response.data);
                } catch (err) {
                    setError(t("Failed to load template data."));
                }
            };

            fetchTemplate();
        }
    }, [templateId, authToken, apiUrl, t]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setFormData((prev) => ({
            ...prev,
            [name]: typeof formData[name] === "boolean" ? value === "true" || value === true : newValue,
        }));
    };

    const handleNumericChange = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: parseFloat(value),
        }));
    };

    const toggleAccordion = (section) => {
        setAccordionState((prev) => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Prepare data to send by extracting only necessary fields
        const payload = {
            ...formData,
            symbol: symbols.find((s) => s.id === formData.symbol)?.id,
            timeframe: timeframes.find((tf) => tf.name === formData.timeframe)?.name,
        };

        try {
            if (templateId) {
                await axios.put(
                    `${apiUrl}/auto-trader/symbol-templates/${templateId}/`,
                    payload,
                    { headers: { Authorization: `Bearer ${authToken}` } }
                );
            } else {
                await axios.post(`${apiUrl}/auto-trader/symbol-templates/`, payload, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
            }
            onSave();
            onClose();
        } catch (err) {
            setError(t("Failed to save template."));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="symbol-template-form">
                    <h2>{templateId ? t("Edit Template") : t("Create Template")}</h2>
                    {error && <div className="error-message">{error}</div>}
                    <form onSubmit={handleSubmit}>
                        {/* Frame: Datos del Instrumento */}
                        <div className="form-content">
                            <fieldset className="form-frame">
                                <legend onClick={() => toggleAccordion('instrumentData')} className="frame-title">
                                    {t("Instrument Data")}
                                </legend>
                                {accordionState.instrumentData && (
                                    <div>
                                        <label>
                                            {t("Symbol")}
                                            <select name="symbol" value={formData.symbol.id} onChange={handleChange} required>
                                                <option value="">{t("Select Symbol")}</option>
                                                {symbols.map((symbol) => (
                                                    <option key={symbol.id} value={symbol.id}>
                                                        {symbol.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                        <label>
                                            {t("Timeframe")}
                                            <select name="timeframe" value={formData.timeframe.name} onChange={handleChange} required>
                                                <option value="">{t("Select Timeframe")}</option>
                                                {timeframes.map((timeframe) => (
                                                    <option key={timeframe.name} value={timeframe.name}>
                                                        {timeframe.name} - {timeframe.description}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                )}
                            </fieldset>

                            {/* Frame: Configuración de Períodos */}
                            <fieldset className="form-frame">
                                <legend onClick={() => toggleAccordion('periodConfig')} className="frame-title">
                                    {t("Period Configuration")}
                                </legend>
                                {accordionState.periodConfig && (
                                    <div>
                                        <label>
                                            {t("Order Periods")}
                                            <NumericInputField
                                                inputProps={{ name: "order_periods", required: true }}
                                                value={formData.order_periods}
                                                minValue={1}
                                                maxValue={1000}
                                                step={1}
                                                onChange={(value) => handleNumericChange("order_periods", value)}
                                            />
                                        </label>
                                        <label>
                                            {t("Analysis Periods")}
                                            <NumericInputField
                                                inputProps={{ name: "analysis_periods", required: true }}
                                                value={formData.analysis_periods}
                                                minValue={0}
                                                maxValue={1000}
                                                step={1}
                                                onChange={(value) => handleNumericChange("analysis_periods", value)}
                                            />
                                        </label>
                                    </div>
                                )}
                            </fieldset>
                            {/* Frame: Restricciones de Mercado */}
                            <fieldset className="form-frame">
                                <legend onClick={() => toggleAccordion('marketRestrictions')} className="frame-title">
                                    {t("Market Restrictions")}
                                </legend>
                                {accordionState.marketRestrictions && (
                                    <div>
                                        <label>
                                            {t("Maximum Spread")}
                                            <NumericInputField
                                                inputProps={{ name: "max_spread", required: true }}
                                                value={formData.max_spread}
                                                minValue={0}
                                                maxValue={1000}
                                                step={1}
                                                onChange={(value) => handleNumericChange("max_spread", value)}
                                            />
                                        </label>
                                        <label>
                                            {t("Maximum Swap")}
                                            <NumericInputField
                                                inputProps={{ name: "max_swap", required: true }}
                                                value={formData.max_swap}
                                                minValue={-1000}
                                                maxValue={1000}
                                                step={0.01}
                                                onChange={(value) => handleNumericChange("max_swap", value)}
                                            />
                                        </label>
                                    </div>
                                )}
                            </fieldset>

                            {/* Frame: Límites de Beneficio */}
                            <fieldset className="form-frame">
                                <legend onClick={() => toggleAccordion('profitLimits')} className="frame-title">
                                    {t("Profit Limits")}
                                </legend>
                                {accordionState.profitLimits && (
                                    <div>
                                        <label>
                                            {t("TP Type")}
                                            <select name="tp_type" value={formData.tp_type} onChange={handleChange} required>
                                                <option value={0}>{t("Percentage")}</option>
                                                <option value={1}>{t("Fixed")}</option>
                                                <option value={3}>{t("Bounded")}</option>
                                            </select>
                                        </label>
                                        <label>
                                            {t("Take Profit (Buy)")}
                                            <NumericInputField
                                                inputProps={{ name: "tp_buy", required: true }}
                                                value={formData.tp_buy}
                                                minValue={0}
                                                maxValue={100}
                                                step={0.01}
                                                digits={2}
                                                onChange={(value) => handleNumericChange("tp_buy", value)}
                                            />
                                        </label>
                                        <label>
                                            {t("Take Profit (Sell)")}
                                            <NumericInputField
                                                inputProps={{ name: "tp_sell", required: true }}
                                                value={formData.tp_sell}
                                                minValue={-100}
                                                maxValue={0}
                                                step={0.01}
                                                digits={2}
                                                onChange={(value) => handleNumericChange("tp_sell", value)}
                                            />
                                        </label>
                                    </div>
                                )}
                            </fieldset>
                            {/* Frame: Límites de Pérdida*/}
                            <fieldset className="form-frame">
                                <legend onClick={() => toggleAccordion('lossLimits')} className="frame-title">
                                    {t("Loss Limits")}
                                </legend>
                                {accordionState.lossLimits && (
                                    <div>
                                        <label>
                                            {t("SL Type")}
                                            <select name="sl_type" value={formData.sl_type} onChange={handleChange} required>
                                                <option value={0}>{t("Percentage")}</option>
                                                <option value={1}>{t("Fixed")}</option>
                                                <option value={3}>{t("Bounded")}</option>
                                            </select>
                                        </label>
                                        <label>
                                            {t("Stop Loss (Buy)")}
                                            <NumericInputField
                                                inputProps={{ name: "sl_buy", required: true }}
                                                value={formData.sl_buy}
                                                minValue={-100}
                                                maxValue={0}
                                                step={0.01}
                                                digits={2}
                                                onChange={(value) => handleNumericChange("sl_buy", value)}
                                            />
                                        </label>
                                        <label>
                                            {t("Stop Loss (Sell)")}
                                            <NumericInputField
                                                inputProps={{ name: "sl_sell", required: true }}
                                                value={formData.sl_sell}
                                                minValue={0}
                                                maxValue={100}
                                                step={0.01}
                                                digits={2}
                                                onChange={(value) => handleNumericChange("sl_sell", value)}
                                            />
                                        </label>
                                    </div>
                                )}
                            </fieldset>
                            {/* Frame: Parámetros de Volumen */}
                            <fieldset className="form-frame">
                                <legend onClick={() => toggleAccordion('volumeParams')} className="frame-title">
                                    {t("Volume Parameters")}
                                </legend>
                                {accordionState.volumeParams && (
                                    <div>
                                        <label>
                                            {t("Volume Order")}
                                            <NumericInputField
                                                inputProps={{ name: "volume_order", required: true }}
                                                value={formData.volume_order}
                                                minValue={0}
                                                maxValue={10000}
                                                step={0.1}
                                                digits={2}
                                                onChange={(value) => handleNumericChange("volume_order", value)}
                                            />
                                        </label>
                                        <label>
                                            {t("Volume Delta")}
                                            <NumericInputField
                                                inputProps={{ name: "volume_delta", required: true }}
                                                value={formData.volume_delta}
                                                minValue={0}
                                                maxValue={10000}
                                                step={0.01}
                                                digits={2}
                                                onChange={(value) => handleNumericChange("volume_delta", value)}
                                            />
                                        </label>
                                        <label>
                                            {t("Volume Max")}
                                            <NumericInputField
                                                inputProps={{ name: "volume_max", required: true }}
                                                value={formData.volume_max}
                                                minValue={0}
                                                maxValue={10000}
                                                step={0.01}
                                                digits={2}
                                                onChange={(value) => handleNumericChange("volume_max", value)}
                                            />
                                        </label>
                                        <label>
                                            {t("Volume Factor")}
                                            <NumericInputField
                                                inputProps={{ name: "volume_factor", required: true }}
                                                value={formData.volume_factor}
                                                minValue={0}
                                                maxValue={10000}
                                                step={0.00001}
                                                digits={5}
                                                onChange={(value) => handleNumericChange("volume_factor", value)}
                                            />
                                        </label>
                                    </div>
                                )}
                            </fieldset>

                            {/* Frame: Gestión de Órdenes */}
                            <fieldset className="form-frame">
                                <legend onClick={() => toggleAccordion('orderManagement')} className="frame-title">
                                    {t("Order Management")}
                                </legend>
                                {accordionState.orderManagement && (
                                    <div>
                                        <label>
                                            {t("Maximum Concurrent Orders")}
                                            <NumericInputField
                                                inputProps={{ name: "max_concurrent_orders", required: true }}
                                                value={formData.max_concurrent_orders}
                                                minValue={0}
                                                maxValue={100}
                                                step={1}
                                                onChange={(value) => handleNumericChange("max_concurrent_orders", value)}
                                            />
                                        </label>
                                    </div>
                                )}
                            </fieldset>
                            {/* Frame: Acción de mercado */}
                            <fieldset className="form-frame">
                                <legend onClick={() => toggleAccordion('marketAction')} className="frame-title">
                                    {t("Market Action")}
                                </legend>
                                {accordionState.marketAction && (
                                    <div className="flex-20">
                                        <label>
                                            {t("Selling")}
                                            <Switch
                                                name="do_sell"
                                                checked={formData.do_sell}
                                                onChange={handleChange}
                                                values={[true, false]}
                                                labels={[]}
                                            />
                                        </label>
                                        <label>
                                            {t("Buying")}
                                            <Switch
                                                name="do_buy"
                                                checked={formData.do_buy}
                                                onChange={handleChange}
                                                values={[true, false]}
                                                labels={[]}
                                            />
                                        </label>
                                    </div>
                                )}
                            </fieldset>
                            {/* Frame: Estado y Programación */}
                            <fieldset className="form-frame">
                                <legend onClick={() => toggleAccordion('stateScheduling')} className="frame-title">
                                    {t("State and Scheduling")}
                                </legend>
                                {accordionState.stateScheduling && (
                                    <div className="flex-20">
                                        <label>
                                            {t("Active")}
                                            <Switch
                                                name="is_active"
                                                checked={formData.is_active}
                                                onChange={handleChange}
                                                values={[true, false]}
                                                labels={[]}
                                            />
                                        </label>
                                    </div>
                                )}
                            </fieldset>
                        </div>
                        <div className="modal-actions">
                            <button type="submit" disabled={loading}>
                                {loading ? t("Saving...") : t("Save")}
                            </button>
                            <button type="button" onClick={onClose}>
                                {t("Cancel")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    );
};

export default SymbolTemplateForm;
